import logo from './logo.svg';
import './App.css';

function Courses() {

    return (
        <div className="App NormalRow">
            <div id='divCourses' className='Home'>
                Courses Go Here
            </div>
        </div>
    );
}

export default Courses;
