import logo from './logo.svg';
import './App.css';
import Courses from './Courses';
import { useState } from 'react';
import Home from "./Home";
import React from 'react';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      Today: new Date().getDate,
      SingleCourseVisible: false,
      FullStackVisible: true,
    };

    this.ShowCourse = this.ShowCourse.bind(this);
  }

  ShowCourse(CourseName) {
    if (CourseName === "FullStack")
      this.setState({ SingleCourseVisible: false, FullStackVisible: true });
    else
      this.setState({ SingleCourseVisible: true, FullStackVisible: false });
  }

  render() {
    return (
      <div className="App NormalRow">
        <div>{this.state.Today.getFullYear}</div>
         {/* onWheel={() => { document.location.href = '#divCourses'; }} */}
        <span className='HomeButton' onClick={() => { document.location.href = '#divHome'; }}>
          <img className='HomeButtonIcon' src={require('./Images/HomeBlue.png')} alt="eXseniarch Academy Home" title="eXseniarch Academy Home" />
        </span>

        <div id='divHome' className='Home'>
          <div className='HomeHeader'>
            <div className='' style={{ display: 'flex', alignContent:'flex-start', textAlign: 'center' }}>exseniarch<br />Academy</div>
            <div className='col11'></div>
          </div>
          <div className='HomeInner'>
            <div className='HomeLogo'><img className='Logo' src={require('./Images/exseniarch Home.png')} alt='exseniarch Academy'></img></div>
            <div className='HomeText'>
              <div className='HomeTextInner' style={{ verticalAlign: 'text-bottom' }}>Learn More,</div>
              <div className='HomeTextInner' style={{ verticalAlign: 'text-top' }}>to be More!</div>
            </div>
          </div>
          <div className='MenuContainer'>
            <div style={{ display: 'flex', flexWrap: 'wrap', minWidth: '25dvw',justifyContent: 'space-around' }}>
              <div className='MenuItem' onClick={() => { document.location.href = '#divCourses'; }}>Courses</div>
              <div className='MenuItem' onClick={() => { document.location.href = '#divProjects'; }}>Projects</div>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', minWidth: '25dvw', justifyContent: 'space-around' }}>
              <div className='MenuItem' onClick={() => { document.location.href = '#divServices'; }}>Services</div>
              <div className='MenuItem' onClick={() => { document.location.href = '#divContacts'; }}>Contacts</div>
            </div>
          </div>
        </div>

        <div id='divCourses' className='Courses'>
          <div className='CourseHeader'>
            <span className={this.state.FullStackVisible ? 'CourseTab CourseTabActive' : 'CourseTab CourseTabInactive'}
              onClick={() => { this.ShowCourse('FullStack'); }}>Go FullStack</span>
            <span className={this.state.FullStackVisible ? 'CourseTab CourseTabInactive' : 'CourseTab CourseTabActive'}
              onClick={() => { this.ShowCourse('SingleCourse') }}>Step-by-Step</span>
          </div>
          <div className='CourseBody'>
            
            <div id='divFullStackCourse' className={this.state.FullStackVisible ? 'CourseVisible' : 'CourseInvisible'}>
              <div className='CourseDesc'>
                  <h1 className='CourseBodyText'>Get ready to become a <strong>FullStack / Startup</strong> Developer</h1>
                  <p className='CourseBodyText'><br />Go fullstack is a program designed to teach you an overall about software development process from code writing, to designing the Architecture of a Software Solution, what you will is to be a practice on: <br />
                    - Agile Methodology or<br />
                    - Own a CI/CD package<br />
                    <br />
                    Alongside with creating your own project during the course, you also will get a good practical knowledge on:<br />
                    <ul>
                      <li><strong>Programming:</strong> Basics of Algorithmic Thinking & Programming with C# / Java / Python</li>
                      <li><strong>SQL Databases:</strong> Relational Database design with Microsoft SQL Server / PostGres</li>
                      <li><strong>No SQL Databases:</strong> MongoDB</li>
                      <li><strong>Webservices:</strong> RESTful API with NodeJS / C#</li>
                      <li><strong>Web design & Markup languages:</strong> HTML, CSS, JavaScript, BootStrap</li>
                      <li><strong>Frontend & UI/UX design:</strong> React / React Native</li>
                      <li><strong>basics on how to use SCRUM, UML, Template design & ...</strong></li>
                    </ul>
                    <br />
                    Entry Condition: <strong>Interview</strong> (Google Meet)<br />
                    Target Audience: Eager and Interested:<br />
                    - to become a <strong>Full Stack Developer</strong><br />
                    - to develop and manage ones own team <strong>Startup software</strong><br />
                    Course Type: <strong>OnLine</strong> (max 3 audience)<br />
                    Course Duration: <strong>285</strong> hrs<br />
                    Course Fee: <strong>5250 € (30% discount-limited time)</strong> (in 3 parts)<br />
                  </p>
              </div>
              <div className='CourseFullStackBackground'>
              </div>
            </div>

            <div id='divSingleCourseCourse' className={this.state.SingleCourseVisible ? 'CourseVisible' : 'CourseInvisible'}>
              <div className='CourseDesc'>                
                  <h1 className='CourseBodyText'><strong>Step-by-Step</strong></h1>
                  <p className='CourseBodyText'><br />Step-by-Step is for those who want to learn about software development and are not sure where they are heading to! You can either begin with:<br />
                    - a Programming/Scripting language <br />
                    - a Database desing & administration course<br />
                    <br />
                    <ul>
                      <li><strong>Programming & Algorithmic Thinking:</strong>
                        <ul>
                          <li>C# / Java / Python</li>
                          <li>JavaScript</li>
                          <li>React, HTML, CSS, Bootstrap (Website Creation)</li>
                          <li>React Native (cross platform Mobile Programming)</li>
                        </ul>
                      </li>
                      <li><strong>Database design & administration:</strong>
                        <ul>
                          <li><strong>Microsoft SQL Server</strong> (Relational databases)</li>
                          <li><strong>Postgres SQL</strong> (Relational databases)</li>
                          <li><strong>MongoDB</strong> (No SQL databases) </li>
                        </ul>
                      </li>
                      <li><strong>Backend & Webservice development:</strong> RESTful API with NodeJS / C#</li>
                    </ul>
                    <br />
                    Entry Condition: <strong>Interview</strong> (Google Meet)<br />
                    Target Audience: Eager and Interested to become: <strong>Programmer</strong> / <strong>Developer</strong> / <strong>Database Admin</strong><br />
                    Course Type: <strong>OnLine & Self-Tailored</strong><br />
                    Course Duration: <strong>depending</strong><br />
                    Course Fee: <strong>depending</strong><br />
                  </p>                
              </div>
              <div className='CourseSingleBackground'>
              </div>
            </div>
          </div>

        </div>

        <div id='divProjects' className='Projects'>
          <div className=''>
            <div className='ServicesHeader'>
              <p>Projects</p>
            </div>
            <div className='ServicesText'>
              <p>These projects are/being developed by our software team, you will find some  </p>
            </div>
          </div>
          <div className="ProjectList">
            <div className="card ProjectCard" onClick={() => { window.open('https://github.com/exseniarch/Cyrus', '_blank'); }}>
              <div className='card-header ProjectCardHeader'>
                <img className="card-img-top ProjectCardImage" src={require('./Images/Projects/Cyrus.png')} alt="Card image cap" />
              </div>
              <div className="card-body">
                <h5 className="card-title">Cyrus</h5>

              </div>
              <div className='card-footer'>
                <p className="card-text ProjectCardBody" >Revealing later</p>
              </div>
            </div>
            <div className="card ProjectCard" onClick={() => { window.open('https://github.com/exseniarch/Rescuake', '_blank'); }}>
              <div className='card-header ProjectCardHeader'>
                <img className="card-img-top ProjectCardImage" src={require('./Images/Projects/Rescuake.png')} alt="Card image cap" />
              </div>
              <div className="card-body">
                <h5 className="card-title">Rescuake</h5>
                <p className="card-text ProjectCardBody">Earthquake Resque Helper</p>
              </div>
              <div className='card-footer'>
                <p className="card-text ProjectCardBody" >Earthquake Resque</p>
              </div>
            </div>
            <div className="card ProjectCard" onClick={() => { window.open('http://www.bazaarito.ir', '_blank'); }}>
              <div className='card-header ProjectCardHeader'>
                <img className="card-img-top ProjectCardImage" src={require('./Images/Projects/bazaarito.png')} alt="Card image cap" />
              </div>
              <div className="card-body">
                <h5 className="card-title">Bazaarito</h5>
                <p className="card-text ProjectCardBody">On-Line Store Mobile Application</p>
              </div>
              <div className='card-footer'>
                <p className="card-text ProjectCardBody" >On-Line Store</p>
              </div>
            </div>
            <div className="card ProjectCard" onClick={() => { window.open('https://github.com/exseniarch/Medrep', '_blank'); }}>
              <div className='card-header ProjectCardHeader'>
                <img className="card-img-top ProjectCardImage" src={require('./Images/Projects/MedRep.png')} alt="Card image cap" />
              </div>
              <div className="card-body">
                <h5 className="card-title">MedRep</h5>
                <p className="card-text ProjectCardBody">Medical reasearch ideas</p>
              </div>
              <div className='card-footer'>
                <p className="card-text ProjectCardBody" >Medical reasearch</p>
              </div>
            </div>
            <div className="card ProjectCard" onClick={() => { window.open('https://github.com/exseniarch/SPi', '_blank'); }}>
              <div className='card-header ProjectCardHeader'>
                <img className="card-img-top ProjectCardImage" src={require('./Images/Projects/SPi.png')} alt="Card image cap" />
              </div>
              <div className="card-body">
                <h3 className="card-title">SPi</h3>
                <p className="card-text ProjectCardBody"></p>
              </div>
              <div className='card-footer'>
                <p className="card-text ProjectCardBody">Stock Market Analysis</p>
              </div>
            </div>
          </div>
          <div className=''>
            <div className='ServicesText'>
              <p>Some project pages will be enabled soon as they are launched</p>
            </div>
          </div>
        </div>
        <div id='divServices' className='Services'>
          <div className=''>
            <div className='ServicesHeader'>
              <p>Services</p>
            </div>
            <div className='ServicesText'>
              <p>We provide you with the following services, which you can feel free contacting us to order/more information on each</p>
            </div>
          </div>
          <div className='ServiceList' style={{ textAlign: 'center', alignContent: 'center', alignItems: 'top' }}>
            {/* <div className='col-sm-4'> */}
              <div className="card ServicesCard">
                <div className='card-header ProjectCardHeader'>
                  <img className="card-img-top ProjectCardImage" src={require('./Images/Services/Seminar.jpg')} alt="Seminars / Webinars" />
                </div>
                <div className="card-header">
                  <h5 className="card-title">Seminar / Webinar</h5>
                </div>
                <div className='card-body'>
                  <p className="card-text ProjectCardBody" >Modern technologies can open a new horizon to the companies' future. Via our Seminar services you can provide a great introduction (say to IoT for example) to a big group of your employses</p>
                </div>
                <div className='card-footer'>
                  <p className="card-text ProjectCardBody" >Comming Soon: IoT (<span className='ServicesLink' onClick={() => { document.location.href = '#divContacts'; }}>register</span>)</p>
                </div>
              </div>
            {/* </div>
            <div className='col-sm-4'> */}
              <div className="card text-left ServicesCard">
                <div className='card-header ProjectCardHeader'>
                  <img className="card-img-top ProjectCardImage" src={require('./Images/Services/Solution.jpg')} alt="Software Solution Development" />
                </div>
                <div className="card-header">
                  <h5 className="card-title">Software Solution</h5>
                </div>
                <div className='card-body'>
                  <p className="card-text ProjectCardBody" >You can order custom Software Solutions, to be analyzed, designed & developed by our team, for your company/startup</p>
                </div>
                <div className='card-footer'>
                  <p className="card-text ProjectCardBody" >Upcomming: <span className='ServicesLink' onClick={() => { document.location.href = '#divProjects'; }}>Products</span></p>
                </div>
              </div>
            {/* </div>
            <div className='col-sm-4'> */}
              <div className="card ServicesCard">
                <div className='card-header ProjectCardHeader'>
                  <img className="card-img-top ProjectCardImage" src={require('./Images/Services/Education.jpg')} alt="Educational Courses & Services" />
                </div>
                <div className="card-header">
                  <h5 className="card-title">Education</h5>
                </div>
                <div className='card-body'>
                  <p className="card-text ProjectCardBody" >with education service either individuals or companies can grow their skills to develop and ship more professional software based on modern technologies</p>
                </div>
                <div className='card-footer'>
                  <p className="card-text ProjectCardBody" >Hottest: <span className='ServicesLink' onClick={() => { document.location.href = '#divCourses'; }}>Go FullStack/Startup</span></p>
                </div>
              </div>
            {/* </div> */}
          </div>

        </div>
        <div id='divContacts' className='Contacts'>
          <div className='ContactInner'>
            <div className='ContactRows' style={{ textAlign: 'left' }}>
              <h2>Contact & Talk to Us</h2>
            </div>
            <div className='ContactRows' style={{ textAlign: 'left' }}>to become a well trained programmer, consult for a software solution or professional webinars feel free to call, email, or hit us up on our social media accounts.</div>
            <div className='ContactRows'></div>
            <div className='ContactRows'></div>
            <div className='ContactConnectionRow'>
              <div className='Contact ContactConnectionInnerText'>
                <div>PHONE</div>
                <div className="Contact ContactValue" onclick="window.open('tel:+15202618335', '_self');">
                  <a href="tel:+1-(520)-261-8335" target='_blank' style={{color: 'inherit'}}>+1-(520)-261-8335</a>
                </div>
              </div>
              <div className='Contact ContactConnectionInnerText'>
                <div>EMAIL</div>
                <div className=" ContactValue" onclick="window.open('mailto:info@exseniarch.com', '_self');">
                <a href="mailto:info@exseniarch.com" target='_blank' style={{color: 'inherit'}}>info@exseniarch.com</a>
                  
                </div>
                <div className=" ContactValue" onclick="window.open('mailto:exseniarch@gmail.com', '_self');">
                <a href="mailto:exseniarch@gmail.com" target='_blank' style={{color: 'inherit'}}>exseniarch@gmail.com</a>
                  
                </div>
              </div>
              <div className='Contact ContactIconContainer'>
                <div>&nbsp;</div>
                <div>
                  <span className="ContactIconContainer" onClick={() => { window.open('http://www.linkedin.com/in/exseniarch', '_blank'); }}>
                    <img src={require('./Images/Contacts/LinkedIn.png')} alt="eXseniarch Academy LinkedIn for On-Line Courses & Webinars request" title="eXseniarch Academy LinkedIn for On-Line Courses & Webinars request" className="ContactsIcon" />
                  </span>
                  <span className="ContactIconContainer" onClick={() => { window.open('http://www.youtube.com/channel/UCvdxbyxCyHQPzZkM1BgDaVg', '_blank'); }}>
                    <img src={require('./Images/Contacts/YouTube.png')} alt="eXseniarch Academy YouTube for On-Line Courses & Webinars request" title="eXseniarch Academy YouTube for On-Line Courses & Webinars request" className="ContactsIcon" />
                  </span>
                  <span className="ContactIconContainer" onClick={() => { window.open('https://wa.me/15202618335', '_self'); }}>
                    <img src={require('./Images/Contacts/Whatsapp.png')} alt="eXseniarch Academy Whatsapp for On-Line Courses & Webinars request" title="eXseniarch Academy Whatsapp for On-Line Courses & Webinars request" className="ContactsIcon" />
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default App;
