
import React from "react";

class Home extends React.Component {
    render() {
        {
            return (
                <div className="App NormalRow">
                    <span className='HomeButton' onClick={() => { document.location.href = '#divHome'; }}>
                        <img className='HomeButtonIcon' src={require('./Images/HomeBlue.png')} alt="eXseniarch Academy Home" title="eXseniarch Academy Home" />
                    </span >
                    <div id='divHome' className='Home'>
                        <div className='HomeHeader'>
                            <div className='col-sm-1' style={{ textAlign: 'center' }}>exseniarch<br />Academy</div>
                            <div className='col11'></div>
                        </div>
                        <div className='HomeInner'>
                            <div className='HomeLogo'><img className='Logo' src={require('./Images/exseniarch Home.png')} alt='exseniarch Academy'></img></div>
                            <div className='HomeText'>
                                <div className='HomeTextInner' style={{ verticalAlign: 'text-bottom' }}>Learn More,</div>
                                <div className='HomeTextInner' style={{ verticalAlign: 'text-top' }}>to be More!</div>
                            </div>

                        </div>
                        <div className='MenuContainer'>
                            <span className='MenuItem' onClick={() => { document.location.href = '#divCourses'; }}>Courses</span>
                            <span style={{ display: 'table-cell' }}>&nbsp;</span>
                            <span className='MenuItem' onClick={() => { document.location.href = '#divProjects'; }}>Projects</span>
                            <span style={{ display: 'table-cell' }}>&nbsp;</span>
                            <span className='MenuItem' onClick={() => { document.location.href = '#divWebinars'; }}>Webinars</span>
                            <span style={{ display: 'table-cell' }}>&nbsp;</span>
                            <span className='MenuItem' onClick={() => { document.location.href = '#divContacts'; }}>Contacts</span>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Home;